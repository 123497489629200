// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__addWrap--SD0Fq {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 8px;\n  margin-top: 8px;\n  border-top: 1px solid #eee;\n}\n.s-module__btn--eZlOQ {\n  background: #EEF2FF !important;\n  color: #4338CA;\n}\n.s-module__btn--eZlOQ:hover {\n  background: #EFEFEF !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/ChannelTagSelect/s.module.less"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;EACA,eAAA;EACA,0BAAA;AAHF;AAMA;EACE,8BAAA;EACA,cAAA;AAJF;AAOA;EACE,8BAAA;AALF","sourcesContent":[".wrap{\n  \n}\n\n.addWrap{\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 8px;\n  margin-top: 8px;\n  border-top: 1px solid #eee;\n}\n\n.btn{\n  background: #EEF2FF !important;\n  color: #4338CA;\n}\n\n.btn:hover{\n  background: #EFEFEF !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addWrap": "s-module__addWrap--SD0Fq",
	"btn": "s-module__btn--eZlOQ"
};
export default ___CSS_LOADER_EXPORT___;
