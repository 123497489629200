// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--ha60C {\n  background: #f9fafb;\n  border: 1px solid #E9ECEF;\n  padding: 32px 16px;\n  border-radius: 12px;\n}\n.s-module__wrap--ha60C .s-module__header--RXKkI {\n  margin-bottom: 8px;\n}\n.s-module__wrap--ha60C .s-module__footer--tfQyM {\n  margin-top: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/HtmlContentInput/s.module.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;AACF;AALA;EAMI,kBAAA;AAEJ;AARA;EASI,eAAA;AAEJ","sourcesContent":[".wrap {\n  background: #f9fafb;\n  border: 1px solid #E9ECEF;\n  padding: 32px 16px;\n  border-radius: 12px;\n  .header{\n    margin-bottom: 8px;\n  }\n  .footer{\n    margin-top: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--ha60C",
	"header": "s-module__header--RXKkI",
	"footer": "s-module__footer--tfQyM"
};
export default ___CSS_LOADER_EXPORT___;
