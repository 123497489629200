// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__header--xcF_H {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 16px;\n}\n.s-module__header--xcF_H .s-module__breadcrumb--YSFXU {\n  margin-bottom: 8px;\n}\n.s-module__header--xcF_H .s-module__breadcrumb--YSFXU .ant-breadcrumb-separator {\n  margin-inline: 23px;\n}\n.s-module__header--xcF_H .s-module__title--TKwrh {\n  font-weight: 700;\n  font-size: 30px;\n  color: #111827;\n}\n.s-module__header--xcF_H .s-module__buttons--j7IZT button + button {\n  margin-left: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/PreviewHeader/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AALA;EAWM,kBAAA;AAHN;AARA;EAQQ,mBAAA;AAGR;AAXA;EAeM,gBAAA;EACA,eAAA;EACA,cAAA;AADN;AAhBA;EAsBQ,iBAAA;AAHR","sourcesContent":[".header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 16px;\n\n    .breadcrumb {\n      :global(.ant-breadcrumb-separator) {\n        margin-inline: 23px;\n      }\n\n      margin-bottom: 8px;\n    }\n\n    .title {\n      font-weight: 700;\n      font-size: 30px;\n      color: #111827;\n    }\n\n    .buttons {\n      button+button {\n        margin-left: 16px;\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "s-module__header--xcF_H",
	"breadcrumb": "s-module__breadcrumb--YSFXU",
	"title": "s-module__title--TKwrh",
	"buttons": "s-module__buttons--j7IZT"
};
export default ___CSS_LOADER_EXPORT___;
