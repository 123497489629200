// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--R7HwY {\n  padding: 30px 24px;\n  border: 1px solid var(--gray);\n  border-radius: 10px;\n  font-family: 'Inter';\n  font-style: normal;\n}\n.s-module__wrap--R7HwY .s-module__header--PiL7_ {\n  margin-bottom: 16px;\n  display: flex;\n  justify-content: space-between;\n}\n.s-module__wrap--R7HwY .s-module__header--PiL7_ .s-module__title--PiopT {\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 20px;\n  color: var(--gray-700);\n}\n.s-module__wrap--R7HwY .s-module__header--PiL7_ .s-module__btns--IE6qA button {\n  margin-left: 8px;\n}\n.s-module__wrap--R7HwY .s-module__body--FMKWg .s-module__item--evJMx {\n  margin-bottom: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/PracticeFrontFromComponents/Service/s.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,6BAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;AACJ;AANA;EAQQ,mBAAA;EACA,aAAA;EACA,8BAAA;AACR;AAXA;EAaY,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AACZ;AAjBA;EAqBgB,gBAAA;AADhB;AApBA;EA4BY,mBAAA;AALZ","sourcesContent":[".wrap {\n    padding: 30px 24px;\n    border: 1px solid var(--gray);\n    border-radius: 10px;\n    font-family: 'Inter';\n    font-style: normal;\n\n    .header {\n        margin-bottom: 16px;\n        display: flex;\n        justify-content: space-between;\n\n        .title {\n            font-weight: 500;\n            font-size: 20px;\n            line-height: 20px;\n            color: var(--gray-700);\n        }\n    \n        .btns {\n            button {\n                margin-left: 8px;\n            }\n        }\n    }\n\n    .body {\n        .item {\n            margin-bottom: 16px;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--R7HwY",
	"header": "s-module__header--PiL7_",
	"title": "s-module__title--PiopT",
	"btns": "s-module__btns--IE6qA",
	"body": "s-module__body--FMKWg",
	"item": "s-module__item--evJMx"
};
export default ___CSS_LOADER_EXPORT___;
