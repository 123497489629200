// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__pdfWrap--o2NzT {\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  width: 200px;\n}\n.s-module__pdfWrap--o2NzT img {\n  width: 100%;\n  margin-bottom: 4px;\n}\n.s-module__pdfWrap--o2NzT a {\n  color: var(--primary-color);\n  text-decoration: underline;\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://./src/components/LicenseImg/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;AACJ;AALA;EAOQ,WAAA;EACA,kBAAA;AACR;AATA;EAYQ,2BAAA;EACA,0BAAA;EACA,iBAAA;AAAR","sourcesContent":[".pdfWrap {\n    display: flex;\n    flex-direction: column;\n    text-align: center;\n    width: 200px;\n\n    img {\n        width: 100%;\n        margin-bottom: 4px;\n    }\n\n    a {\n        color: var(--primary-color);\n        text-decoration: underline;\n        font-weight: bold;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pdfWrap": "s-module__pdfWrap--o2NzT"
};
export default ___CSS_LOADER_EXPORT___;
