// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--rM6r6 {\n  background: #fff;\n  padding: 30px;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n.s-module__wrap--rM6r6 h2 {\n  text-align: center;\n  font-size: 24px;\n  font-weight: bolder;\n  color: var(--gray-900);\n  line-height: 48px;\n  flex-shrink: 0;\n}\n.s-module__wrap--rM6r6 .s-module__content--OtHkN {\n  padding: 0 30px;\n  flex-grow: 1;\n  overflow-y: scroll;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ManageSubscription/s.module.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;AACJ;AANA;EAOQ,kBAAA;EACA,eAAA;EACA,mBAAA;EACA,sBAAA;EACA,iBAAA;EACA,cAAA;AAER;AAdA;EAeQ,eAAA;EACA,YAAA;EACA,kBAAA;AAER","sourcesContent":[".wrap {\n    background: #fff;\n    padding: 30px;\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    h2 {\n        text-align: center;\n        font-size: 24px;\n        font-weight: bolder;\n        color: var(--gray-900);\n        line-height: 48px;\n        flex-shrink: 0;\n    }\n    .content {\n        padding: 0 30px;\n        flex-grow: 1;\n        overflow-y: scroll;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--rM6r6",
	"content": "s-module__content--OtHkN"
};
export default ___CSS_LOADER_EXPORT___;
