// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--hBkLi {\n  padding-bottom: 36px;\n}\n.s-module__wrap--hBkLi .s-module__infoWrap--VMDVY {\n  padding: 0;\n  margin-top: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ManageSubscription/components/SubscriptionInfo/s.module.less"],"names":[],"mappings":"AAAA;EACI,oBAAA;AACJ;AAFA;EAIQ,UAAA;EACA,gBAAA;AACR","sourcesContent":[".wrap {\n    padding-bottom: 36px;\n\n    .infoWrap {\n        padding: 0;\n        margin-top: 16px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--hBkLi",
	"infoWrap": "s-module__infoWrap--VMDVY"
};
export default ___CSS_LOADER_EXPORT___;
