// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--t3Tuk .s-module__timePicker--ex6tU {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.s-module__wrap--t3Tuk .s-module__timeSelect--sxFd6 {\n  width: 120px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/TimePicker/s.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,mBAAA;EACA,mBAAA;AAAJ;AAJA;EAQI,YAAA;AADJ","sourcesContent":[".wrap {\n  .timePicker {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n  }\n\n  .timeSelect {\n    width: 120px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--t3Tuk",
	"timePicker": "s-module__timePicker--ex6tU",
	"timeSelect": "s-module__timeSelect--sxFd6"
};
export default ___CSS_LOADER_EXPORT___;
