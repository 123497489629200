// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/helloklarityOps/great.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("assets/helloklarityOps/average.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("assets/helloklarityOps/poor.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__radioItem--NbVCo {\n  display: flex;\n  align-items: center;\n}\n.s-module__great--R4sv_ {\n  width: 29px;\n  height: 29px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center no-repeat;\n  background-size: cover;\n}\n.s-module__average--QWz2i {\n  width: 29px;\n  height: 29px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") center no-repeat;\n  background-size: cover;\n}\n.s-module__poor--S24WB {\n  width: 29px;\n  height: 29px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") center no-repeat;\n  background-size: cover;\n}\n", "",{"version":3,"sources":["webpack://./src/components/PorivderMappingModals/PatientRatingUpdateModal/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;AACJ;AAEA;EACI,WAAA;EACA,YAAA;EACA,oEAAA;EACA,sBAAA;AAAJ;AAGA;EACI,WAAA;EACA,YAAA;EACA,oEAAA;EACA,sBAAA;AADJ;AAIA;EACI,WAAA;EACA,YAAA;EACA,oEAAA;EACA,sBAAA;AAFJ","sourcesContent":[".radioItem {\n    display: flex;\n    align-items: center;\n}\n\n.great {\n    width: 29px;\n    height: 29px;\n    background: url('assets/helloklarityOps/great.svg') center no-repeat;\n    background-size: cover;\n}\n\n.average {\n    width: 29px;\n    height: 29px;\n    background: url('assets/helloklarityOps/average.svg') center no-repeat;\n    background-size: cover;\n}\n\n.poor {\n    width: 29px;\n    height: 29px;\n    background: url('assets/helloklarityOps/poor.svg') center no-repeat;\n    background-size: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioItem": "s-module__radioItem--NbVCo",
	"great": "s-module__great--R4sv_",
	"average": "s-module__average--QWz2i",
	"poor": "s-module__poor--S24WB"
};
export default ___CSS_LOADER_EXPORT___;
