// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--A3Eyw {\n  line-height: 20px;\n  font-size: 12px;\n  color: #fff;\n  padding: 0 6px;\n  border-radius: 4px;\n  display: inline-block;\n  min-width: 62px;\n  text-align: center;\n}\n.s-module__terrible--lQsXg {\n  background: #E45556;\n}\n.s-module__bad--A1dOh {\n  background: #E37A4E;\n}\n.s-module__okay--XVsr1 {\n  background: #EDBF4D;\n}\n.s-module__good--m6DRf {\n  background: #99C673;\n}\n.s-module__amazing--MXu10 {\n  background: #6AB663;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ScoreLabel/s.module.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,eAAA;EACA,WAAA;EACA,cAAA;EACA,kBAAA;EACA,qBAAA;EACA,eAAA;EACA,kBAAA;AACF;AAEA;EACE,mBAAA;AAAF;AAGA;EACE,mBAAA;AADF;AAIA;EACE,mBAAA;AAFF;AAKA;EACE,mBAAA;AAHF;AAMA;EACE,mBAAA;AAJF","sourcesContent":[".wrap {\n  line-height: 20px;\n  font-size: 12px;\n  color: #fff;\n  padding: 0 6px;\n  border-radius: 4px;\n  display: inline-block;\n  min-width: 62px;\n  text-align: center;\n}\n\n.terrible {\n  background: #E45556;\n}\n\n.bad {\n  background: #E37A4E;\n}\n\n.okay {\n  background: #EDBF4D;\n}\n\n.good {\n  background: #99C673;\n}\n\n.amazing {\n  background: #6AB663;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--A3Eyw",
	"terrible": "s-module__terrible--lQsXg",
	"bad": "s-module__bad--A1dOh",
	"okay": "s-module__okay--XVsr1",
	"good": "s-module__good--m6DRf",
	"amazing": "s-module__amazing--MXu10"
};
export default ___CSS_LOADER_EXPORT___;
