// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__selector--ezG4d {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Selector/s.module.less"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ","sourcesContent":[".selector {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selector": "s-module__selector--ezG4d"
};
export default ___CSS_LOADER_EXPORT___;
