// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/common/wrongIcon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--jd7re {\n  display: flex;\n  margin-bottom: 16px;\n  padding: 16px 18px;\n  background-color: var(--red-50);\n  border-radius: 6px;\n}\n.s-module__wrap--jd7re .s-module__errorIcon--nQvST {\n  margin-right: 14px;\n  width: 20px;\n  height: 20px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: contain;\n}\n.s-module__wrap--jd7re .s-module__errorInfo--Uo9GM {\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 20px;\n  color: var(--red-800);\n}\n", "",{"version":3,"sources":["webpack://./src/components/PracticeFrontFromComponents/ErrorBlock/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,+BAAA;EACA,kBAAA;AACJ;AANA;EAQQ,kBAAA;EACA,WAAA;EACA,YAAA;EACA,yDAAA;EACA,4BAAA;EACA,2BAAA;EACA,wBAAA;AACR;AAfA;EAkBQ,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;AAAR","sourcesContent":[".wrap {\n    display: flex;\n    margin-bottom: 16px;\n    padding: 16px 18px;\n    background-color: var(--red-50);\n    border-radius: 6px;\n\n    .errorIcon {\n        margin-right: 14px;\n        width: 20px;\n        height: 20px;\n        background-image: url('assets/common/wrongIcon.svg');\n        background-repeat: no-repeat;\n        background-position: center;\n        background-size: contain;\n    }\n\n    .errorInfo {\n        font-family: 'Inter';\n        font-style: normal;\n        font-weight: 500;\n        font-size: 14px;\n        line-height: 20px;\n        color: var(--red-800);\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--jd7re",
	"errorIcon": "s-module__errorIcon--nQvST",
	"errorInfo": "s-module__errorInfo--Uo9GM"
};
export default ___CSS_LOADER_EXPORT___;
